import React, { useContext } from 'react';
import styled from "styled-components";
import { UIContext } from './UIContext';
import { Link } from "gatsby";
import Banner from "./Banner";
import Multilinks from "./Multilinks";
import CtaPrimary from "./CtaPrimary";
import Img from "./Img";
import RatingWidget from "./RatingWidget";

const StyledMainLeftColumn = styled.div`
  grid-area: left;
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  
  .card:first-child {
    padding-bottom: 0;
}
`;

const bannerHeadline = {
  en: "“Expert Rankings & measurable Results”",
  de: "“Rankings & messbare Ergebnisse vom Profi”",
  da: "“Ekspert Rangeringer & målbare Resultater”",
  se: "“Expert Rankningar & mätbara Resultat”",
  no: "“Ekspert Rangeringer & målbare Resultater”",
  nl: "“Expert Rankings & meetbare Resultaten”",
  fi: "“Asiantuntijan Sijoitukset & mitattavat Tulokset”", // Finnish translation
  fr: "“Classements d'expert & résultats mesurables”" // French translation
};

const bannerCta = {
  en: "Services",
  de: "Leistungen",
  da: "Services",
  se: "Tjänster",
  no: "Tjenester",
  nl: "Diensten",
  fi: "Palvelut", // Finnish translation
  fr: "Services" // French translation
};

// Shortlist
// Rankings und messbare Ergebnisse vom Profi
// SEO und Analytics-Dienstleistungen vom Profi
// Traffic und Conversions vom Profi
// Digitale Assets optimieren
// Ergebnisse durch SEO & Analytics
// Mehr Kunden durch Rankings & messbare Ergebnisse

// Einzigartig
// Wachstum
// DE
// Professionelle SEO - und Analytics - Dienstleistungen für nachhaltigen Erfolg
// Professionelle SEO - und Analytics - Dienstleistungen für Traffic und Conversions
// Top - Rankings und messbare Ergebnisse
// Webanalyse und SEO - Dein Weg zu Traffic und Conversions
// Webanalyse und SEO - Dein Weg zum nachhaltigen Erfolg
// - Online-Business durch SEO transformieren
// - Online-Auftritt durch Daten-SEO verstärken
// - Mehr Kunden durch Suchmaschinenoptimierung
// - Brücke zu besseren Rankings
// - Lösungen für SEO und Webanalyse
// - Sichtbarkeit und Erfolg durch SEO
// - Spitze der Suchergebnisse erreichen
// - Online-Sichtbarkeit mit SEO maximieren
// - SEO und Analytics für nachhaltigen Erfolg
// - Traffic und Konversionen steigern
// - Top-Rankings und Ergebnisse durch SEO
// - Webanalyse und SEO-Beratung für Spitzenplatzierung


const serviceLinks = {
  en: "/en/services",
  de: "/de/leistungen",
  da: "/da/services",
  fi: "/fi/palvelut",
  fr: "/fr/les-services",
  nl: "/nl/diensten",
  no: "/no/tjenester",
  se: "/se/tjänster",
  x_default: "/en/services"
};

const MainLeftColumn = () => {
  const { language } = useContext(UIContext); // Destructured for direct access
  const lang = language || "en"; // Fallback to "en" if language is not defined

  // Determine the correct link, falling back to x-default if not found
  const serviceLink = serviceLinks[lang] || serviceLinks.x_default;

  return (
    <StyledMainLeftColumn className="main-left">
      <Banner>
        <h1 className="banner__headline text headline">{bannerHeadline[lang]}</h1>
        <div className="banner__image">
          <Img clickable="false" src="about/matthias-kupperschmidt-frontal-collar-transparent-square.png" alt="matthias kupperschmidt portrait" />
        </div>

        <div className="features">
          <Link className="cta-primary" to={serviceLink}>
            <CtaPrimary color="red" className="cta-primary--align">{bannerCta[lang]}</CtaPrimary>
          </Link>
          <RatingWidget className="rating" lang={language} />
        </div>
      </Banner>
      <Multilinks language={lang} genre={'front'} />
    </StyledMainLeftColumn>
  );
};

export default React.memo(MainLeftColumn);


