import React from 'react';
import Layout from "../../components/Layout";
import SEO from "../../components/Seo";
import MainFrontpage from "../../components/MainFrontpage";
import MainLeftColumn from "../../components/MainLeftColumn";
import MainRightColumn from "../../components/MainRightColumn";
import { useAlternateLangs } from "../../components/Hreflangs";

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en"
);



const Index = ({ location }) => { // Receive location as a prop
  return (
    <Layout location={location} alternateLangs={alternateLangs}>
      <React.Fragment>
        <SEO
          title="Blue River Mountains | Google Analytics & SEO -konsultointi"
          lang="fi"
          description="Konsultointia SEO:lle ja Web-analytiikalle freelance-pohjalta. Opi SEO:sta tai Google Analyticsista tutoriaaleissamme tai ota meihin yhteyttä, jos tarvitset apua kehittyneempiin ongelmiin."
          image="homepage-screenshot"
          alternateLangs={alternateLangs}
          canonical="/fi"
        />
        <MainFrontpage>
          {/* Since MainLeftColumn and MainRightColumn need the language, they can consume the context within themselves. */}
          <MainLeftColumn />
          <MainRightColumn />
        </MainFrontpage>
      </React.Fragment>
    </Layout>
  );
};

export default React.memo(Index);
